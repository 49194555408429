#blog-content {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding-bottom: 1rem;
    }
  }
}