#initials {
  height: 100%;
  aspect-ratio: 1;
  display: flex;
  place-content: center;

  border: none;
  background: none;
  color: inherit;
  padding: 0;

  > .initial {
    height: unset;
    margin: auto;

    font-size: 3em;
    cursor: pointer;
    user-select: none;

    transition: transform 0.2s ease-in-out;
    &:hover:not([data-spinning]) {
      transform: rotate(15deg) scale(1.2);
    }
  }

  &[data-spinning='0'] .initial {
    animation: spin-Y 0.2s ease-in-out infinite;
  }

  &[data-spinning='1'] .initial {
    animation: spin-Z 0.3s linear infinite;
  }

  &[data-spinning='2'] .initial {
    animation: clock-wipe 0.6s linear infinite;
  }
}

@keyframes spin-Y {
  from {
    transform: scaleX(1.2) scaleY(1.2);
  }

  50% {
    transform: scaleX(-1.2) scaleY(1.2);
  }

  to {
    transform: scaleX(1.2) scaleY(1.2);
  }
}

@keyframes spin-Z {
  from {
    transform: scale(1.2) rotate(0deg);
  }

  to {
    transform: scale(1.2) rotate(360deg);
  }
}

@keyframes clock-wipe {
  0%, 100% {
    transform: scale(1.2);
    clip-path: polygon(50% 50%, 50% 0, 100% 0, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0)
  }

  6.25% {
    clip-path: polygon(50% 50%, 100% 0, 100% 0, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0)
  }

  12.5% {
    clip-path: polygon(50% 50%, 100% 50%, 100% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0)
  }

  18.75% {
    clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0)
  }

  25% {
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0)
  }

  31.25% {
    clip-path: polygon(50% 50%, 0 100%, 0 100%, 0 100%, 0 100%, 0 100%, 0 100%, 0 50%, 0 0, 50% 0)
  }

  37.5% {
    clip-path: polygon(50% 50%, 0 50%, 0 50%, 0 50%, 0 50%, 0 50%, 0 0, 0 50%, 0 0, 50% 0)
  }

  43.75% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0, 0 0, 0 50%, 0 0, 50% 0)
  }

  50% {
    clip-path: polygon(50% 50%, 50% 0, 50% 0, 50% 0, 50% 0, 50% 0, 0 0, 0 50%, 0 0, 50% 0)
  }

  56.25% {
    clip-path: polygon(50% 50%, 50% 0, 100% 0, 100% 0, 100% 0, 100% 0, 100% 0, 100% 0, 100% 0, 100% 0)
  }

  62.5% {
    clip-path: polygon(50% 50%, 50% 0, 100% 0, 100% 50%, 100% 50%, 100% 50%, 100% 50%, 100% 50%, 100% 50%, 100% 50%)
  }

  68.75% {
    clip-path: polygon(50% 50%, 50% 0, 100% 0, 100% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 100%)
  }

  75% {
    clip-path: polygon(50% 50%, 50% 0, 100% 0, 100% 50%, 100% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%)
  }

  81.25% {
    clip-path: polygon(50% 50%, 50% 0, 100% 0, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 100%, 0 100%, 0 100%)
  }

  87.5% {
    clip-path: polygon(50% 50%, 50% 0, 100% 0, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 50%, 0 50%)
  }

  93.75% {
    clip-path: polygon(50% 50%, 50% 0, 100% 0, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 0 0)
  }
}