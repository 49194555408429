header {
  position: sticky;
  top: 0;
  z-index: 1;

  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 100%;

  overflow-x: hidden;

  container-name: header;
  container-type: inline-size;

  > * {
    grid-area: 1 / 1;
  }

  .bar {
    width: 100%;
    height: 5em;

    display: flex;
    gap: 1em;

    background-color: var(--highlight);
    color: var(--COLOR-white);

    > * {
      z-index: 2;
    }
  }

  .background-colour-banners {
    width: 100%;

    grid-area: 1 / 1;

    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 100%;

    pointer-events: none;
    transition: transform 0.5s ease;

    > .banner {
      z-index: 0;
      width: 100%;
      height: 100%;
      background-color: var(--highlight-background-colour);
      transition: background-color var(--theme-transition-duration) ease;
    }
  }
}

@container header (max-width: 400px) {
  header > .bar > #initials {
    display: none;
  }
}