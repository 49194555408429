#home-content {
  h2,
  h3,
  h4 {
    margin: 0;
  }

  a {
    color: var(--COLOR-red);
    text-decoration: underline;
  }
}