html {
  font-family: 'Noto Sans', sans-serif;
}

body,
#root {
  min-height: 100vh;
  margin: 0;
  background-color: var(--background);
  color: var(--primary);

  transition:
    background-color var(--theme-transition-duration) ease-in-out,
    color var(--theme-transition-duration) ease-in-out;

  &.preload, &.preload * {
    transition: none;
    display: none;
  }
}

a {
  color: inherit;
  text-decoration: none;
}
