#now-content {
  .update {
    margin-top: 5em;

    display: flex;
    justify-content: space-between;

    h3,
    h4 {
      margin: 0;
    }

    ul {
      margin: 0 0 0.5em 0;
      padding-left: 0.25em;
      list-style-type: none;
    }

    .update-left {
      order: 0;

      ul > li:before {
        content: '>';
        margin-right: 0.25em;
      }
    }

    .update-right {
      order: 1;
      text-align: right;

      ul > li:after {
        content: '<';
        margin-left: 0.4em;
      }
    }
  }

  .update-image {
    flex-basis: 35%;
    height: min-content;
    transition: all 0.25s ease-in-out;
    line-height: 0;
    font-size: 0.8em;

    &.update-left {
      clip-path: polygon(0 0, calc(100% - 6em) 0, 100% 6em, 100% 100%, 6em 100%, 0 calc(100% - 6em));
      &:hover {
        clip-path: polygon(0 0, 100% 0, 100% 0, 100% 100%, 0 100%, 0 100%);
      }
    }

    &.update-right {
      clip-path: polygon(6em 0, 100% 0, 100% calc(100% - 6em), calc(100% - 6em) 100%, 0 100%, 0 6em);
      &:hover {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 0 100%, 0 0);
      }
    }

    &:hover {
      transform: scale(1.1);
    }

    img {
      width: 100%;
    }
  }
}
