.page-carousel-container {
  overflow-x: hidden;
  container-name: page-carousel-container;
  container-type: inline-size;

  > .page-carousel {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 100%;
    transition: transform 0.5s ease;

    > * > * {
      max-width: 1000px;
      margin: 0 auto;
    }
  }

  &.for-highlights {
    transition: max-height 0.5s ease;
    max-height: 1000px;

    &.minimised {
      max-height: 0;
    }
  }
}
