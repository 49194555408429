nav {
  > ul {
    height: 100%;
    width: 100%;

    margin: 0;
    padding: 0;

    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    align-items: center;

    list-style: none;

    > li {
      height: 100%;

      display: flex;
      position: relative;

      > a {
        padding: 0 1em;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        font-weight: bold;
        text-align: center;
        transition: margin-top 0.2s ease-in-out;

        &::before {
          content: '';
          width: 100%;
          height: 0;

          position: absolute;
          top: 0;

          border-radius: 0 0 2px 2px;
          background-color: var(--highlight);
          transition: height 0.2s ease-in-out;
        }
      }

      &[data-active='true'] {
        > a {
          // cursor: default;
          margin-top: 2em;
        }
      }

      &:hover:not([data-active='true']) > a {
        margin-top: 1em;
        &::before {
          height: 2em;
        }
      }
    }
  }
}
