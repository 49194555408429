.highlight-wrapper > .highlight-container > [id=download-highlight] {
  justify-content: center;
  margin-top: 2rem;
}

.download-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h1 {
    margin-bottom: 0.5rem;
    padding: 0;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  h3 {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
  }

  a {
    align-self: flex-end;
    margin-top: 1rem;
  }

  button {
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    background-color: var(--COLOR-white);
    color: var(--COLOR-brown);
    box-shadow: inset 0px 0px 0.5rem rgba(0, 0, 0, .5);
    transition: all 0.25s ease-in-out;

    clip-path: polygon(0.5em 0, 100% 0, 100% calc(100% - 0.5em), calc(100% - 0.5em) 100%, 0 100%, 0 0.5em);
    &:hover {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 0 100%, 0 0);
      transform: scale(1.1);
    }

    > b {
      &::before {
        content: '💾';
        margin-right: 0.5rem;
      }
    }
  }
}
